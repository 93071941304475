import React, { Component } from 'react';
import './style.css';
import { Table } from './table';
import { Loading } from '../../components/loading';
import { doAjax } from '../../helpers/doAjax';

export class Registrations extends Component {
  constructor() {
    super();
    this.state = { 
      validations: [],
      all_offset: 0,
      portalAccountID_data: {},
      failed_offset: 0,
      data: [],
      failed_data: [],
      selectedPortalAccountId: null,
      selected: 'All',
      searchTerm: ''
    };
    this.Select = this.Select.bind(this);
    this.SetOffset = this.SetOffset.bind(this);
    this.Request = this.Request.bind(this);
    this.SetSearchTerm = this.SetSearchTerm.bind(this);
  }

  async componentDidMount() {
    this.Request();
  }

  SetOffset(selected, new_offset = 0, portal_account_id = null ) {
    if (selected === "Failed") {
      this.setState({
        failed_offset: new_offset
      });
    } else {
      this.setState({
        all_offset: new_offset
      });
    }
    if (portal_account_id !== null) {
      this.setState({
        [`${portal_account_id}_offset`]:  new_offset
      });
    }
  }
  
  async Request(selected, new_offset = 0, portal_account_id = null) {
    const {
      all_offset,
      failed_offset,
      failed_data,
    } = this.state;

    var offset = 0;
    var count = 0;

    if (portal_account_id !== null && selected === "Search") {
      if(`${portal_account_id}_offset` in this.state && `${portal_account_id}_data` in this.state) {
        offset = this.state[`${portal_account_id}_offset`];
        count = this.state[`${portal_account_id}_data`].length;
      }
    } else if (selected === "Failed") {
      offset = failed_offset;
      count = failed_data.length;
    } else {
      offset = all_offset;
      count = this.state.data.length;
    }


    if ((new_offset > offset) || (count === 0 && offset === 0)) {
      this.SetOffset(selected, new_offset + 1);
      var baseurl = process.env.REACT_APP_MIDDLEWARE_API !== undefined ? process.env.REACT_APP_MIDDLEWARE_API : '';
      const logdb_and_latest_bs = `${baseurl}api/latest_logs?status=${selected}&offset=${new_offset}&portal_account_id=${portal_account_id}`;
      var latest_logs_data = await doAjax(logdb_and_latest_bs);
      if (portal_account_id !== null && selected === "Search") {
        var prev_data = [];
        if (`${portal_account_id}_data` in this.state) {
          prev_data = this.state[`${portal_account_id}_data`]
        }
        if (latest_logs_data.length === 0) {
          latest_logs_data = false
        }
        if (offset === 0) {
          this.setState({
            [`${portal_account_id}_data`]: latest_logs_data
          })
        } else {
          this.setState({
            [`${portal_account_id}_data`]: [...prev_data, ...latest_logs_data]
          })
        }
      } else if (selected === "Failed") {
        this.setState({
          failed_data: [...this.state.failed_data, ...latest_logs_data]
        })
      } else {
        this.setState({
          data: [...this.state.data, ...latest_logs_data]
        })
      }
    }


  }

  isSelected(value) {
    var selected = '';
    if (value === this.state.selected) {
      selected = 'options_selected';
    }
    return 'options_title ' + selected;
  }
  
  Select(value) {
    this.setState({
      selected: value
    });
    this.Request(value, 0, this.state.selectedPortalAccountId);
  }

  SetSearchTerm(event) {
    this.setState({
      selectedPortalAccountId: event.target.value
    });
  };

  render() {
    const {
      failed_data,
      data,
      selected,
      selectedPortalAccountId
    } = this.state;
    var table_data = [];
    if (selected === "Failed") {
      table_data = failed_data;
    } else if (selected === "Search") {
      if (selectedPortalAccountId == null) {
        table_data = false;
      } else if (`${selectedPortalAccountId}_data` in this.state) {
        table_data = this.state[`${selectedPortalAccountId}_data`];
      } else {
        table_data = [];
      }
    } else {
      table_data = data;
    }

    return (
      <div className="App">
        <div className="page_title">
          <a href="/" className="page_home_link">
            Home &gt;
          </a>
          <div className="page_current_title">
            Registrations Page
          </div>
        </div>
        <div className="options_container">
          <div className={this.isSelected("All")} onClick={() => this.Select("All")}>
            Full History
          </div>
          <div className={this.isSelected("Failed")} onClick={() => this.Select("Failed")}>
            Failed
          </div>
          <div className={`${this.isSelected("Search")} options_search`} >
            <input
              className="options_input_content"
              type="text"
              placeholder="Search"
              value={selectedPortalAccountId}
              onChange={this.SetSearchTerm}
            />
            <span className="options_input_content" onClick={() => this.Select("Search")}>
              Search
            </span>
          </div>
        </div>
        {
          table_data === false && 
          selectedPortalAccountId != null && 
          <div className="registration_search_empty"> No Results Found </div>
        }
        {
          table_data === false &&
          selectedPortalAccountId == null && 
          <div className="registration_search_empty"> Search Results will appear here </div>
        }
        {
          table_data.length === 0 && <Loading/>
        }
        {
          table_data.length !== 0 && 
          table_data !== false && 
          <Table
            selected={selected}
            table_data={table_data}
            Request={this.Request}
          />
        } 
        
      </div>
    );
  }
}

export default Registrations;
