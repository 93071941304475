import React, {Component} from 'react';
import './style.css';

export const MonthGraph = ({month}) => (
  <td>
    {
      month.length === 1 &&
      month[0] === "Empty" &&
      <span className=""></span>
    }
    {
      month.length > 0 &&
      month[0] === "X" &&
      <span className="has_bills"></span>
    }
    {
      month.length === 0 &&
      <span className="missing_bills"></span>
    }
  </td>
);

// export const DateGraph = ({dates}) => {
export class DateGraph extends Component {
  
  getData = () => {
    var dates = [...this.props.dates];
    var dateSet = {};
    var finalYear = 0;
    var firstYear = 0;
    var found = false;
    var firstfound = false;
    while(dates.length > 0) {
      var startdate = dates[0];
      if (startdate === undefined) {
        dates.splice(0, 1);
      } else if (startdate['BillingPeriodEnd'] === null) {
        dates.splice(0, 1);
      } else {
        if (firstYear === 0) {
          firstYear = startdate["Year"];
        }
        var year = startdate["Year"];
        finalYear = year;

        if(!(year in dateSet)) dateSet[year] = {};
        for (var month = 12; month > 0; month--) {
          dateSet[year][month] = [];
          for (var i = dates.length - 1; i >= 0; i--) {
            // var date = new Date(dates[i]);
            var date = dates[i];
            if (date["Year"] === year && date["Month"] === month) {
              dateSet[year][month].push('X');
              dates.splice(i, 1);
            }
          }
        }
      }
    }
    if (finalYear !== 0) {
      for (var _month = 12; _month > 0; _month--) {
        if (!found && dateSet[finalYear][_month].length === 0) {
          dateSet[finalYear][_month] = ["Empty"];
        } else {
          found = true;
        }
        if (_month === 0) {
          found = true;
        }
      } 
    }
    if (firstYear !== 0) {
      for (_month = 1; _month < 12; _month++) {
        if (!firstfound && dateSet[firstYear][_month].length === 0) {
          dateSet[firstYear][_month] = ["Empty"];
        } else {
          firstfound = true;
        }
        if (_month === 0) {
          firstfound = true;
        }
      } 
    }
    
    return dateSet;
  }

  render() {
    const {
      dates
    } = this.props;
    return (
      <div>
      { 
        dates.length > 0 && 
          <table className="dategraph_table">
            <tbody>
              <tr>
                <td>Date</td>
                <td>Jan</td>
                <td>Feb</td>
                <td>Mar</td>
                <td>Apr</td>
                <td>May</td>
                <td>Jun</td>
                <td>Jul</td>
                <td>Aug</td>
                <td>Sep</td>
                <td>Oct</td>
                <td>Nov</td>
                <td>Dec</td>
              </tr>
              {
                Object.entries(this.getData()).map(([key, value]) => (
                  key !== "null" &&
                  <tr key={key}>
                    <td>{key}</td>
                    {
                      [...Array(12)].map((x, i) =>
                        <MonthGraph month={value[i+1]} />
                      )
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        }
    </div>
  );

  }
}
