import React, { Component } from 'react';
import './style.css';
import { BillingStatement }  from '../statements';
import { Loading } from '../../components/loading';
import { doAjax } from '../../helpers/doAjax';
import { ButtonClass } from '../../helpers/button_class';

export class ValidationItem extends Component {
  constructor() {
    super();
    this.state = { 
      expanded: false,
      data: null
    };
    this.switchExpand = this.switchExpand.bind(this);
  }

  async switchExpand() {
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
    var baseurl = process.env.REACT_APP_MIDDLEWARE_API !== undefined ? process.env.REACT_APP_MIDDLEWARE_API : '';
    const url = `${baseurl}api/billingstatements?id=${this.props.validation.BillingStatementsId}`;
    var data = await doAjax(url);
    this.setState({
      data: data['message']
    });
  }

  render() {
    const {
      expanded,
      data
    } = this.state;
    const {
      validation
    } = this.props;
    return (
      <div className="validation_row_container">
        <div className="validation_row">
          <div>
            {validation.ValidationsId}
          </div>
          <div>
            {validation.PortalAccountId}
          </div>
          <div>
            {validation.BillingStatementsId}
          </div>
          { 
            validation.Status === "Pass" &&
            <div className="validation_pass">
              {validation.Status}
            </div>
          }
          { 
            validation.Status !== "Pass" &&
            <div className="validation_fail">
              {validation.Status}
            </div>
          }
          <div>
            <button className={ButtonClass(this.state.expanded)} onClick={this.switchExpand}>
              {this.state.expanded ? 'CLOSE' : 'OPEN'}
            </button>
          </div>
        </div>
        {
          expanded &&
          data === null &&
          <Loading/>
        }
        {
          expanded &&
          data !== null &&
          data['BillingStatements'] !== null &&
          <BillingStatement
            data={validation}
            statement={data['BillingStatements']['Statements']}
            charges={data['BillingStatements']['Charges']}
          />
        }
      </div>
    );
  }
}

export default ValidationItem;
