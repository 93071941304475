import React from 'react';
import { 
  FPFeatureCard 
} from '../../components/frontpagefeature';
import '../../components/instructions/style.css';
import './style.css';

export const NotLoggedIn = () =>(
  <div className="App">

    <div className="collector_main_image_bg not_logged_in_color" >
      <div className="collector_main_image_inner" >
        <div className="collector_main_details">
          <h1>
            Bill Collector
          </h1>
          <div>
            An application to help get all your bills in one
            easy to use location. Please Login to access the bill
            collector.
          </div>
          <a href="/login">
            <button className="submitbutton medium_size">
              Login
            </button>
          </a>
        </div>
        <img
          className="collector_main_image" 
          src="/img/collector.png"
          alt="Collector Visual"
        />
      </div>
    </div>

    <div className="NotLoggedIn_blank">

    </div>
    <div>
      <h2>
        Features
      </h2>
      <div className="fp_container">
        <FPFeatureCard
          title="View Validations"
          subtext="View the Latest Validations Of Crawled Bills"
          image_src="/img/check_mark.png"
          image_alt="check mark"
          link="/"
        />
        <FPFeatureCard
          title="Billing Registration Status"
          subtext="View the status of all Billing Registrations"
          image_src="/img/starter.png"
          image_alt="check mark"
          link="/"
        />
        <FPFeatureCard
          title="Drop in Bill Scraper"
          subtext="Drop in a PDF you want the system to read"
          image_src="/img/scraper_reader.png"
          image_alt="check mark"
          link="/"
        />
        <FPFeatureCard
          title="Review Latest Requests"
          subtext="View the Latest Send off Crawler Requests"
          image_src="/img/reviews.png"
          image_alt="check mark"
          link="/"
        />
        <FPFeatureCard
          title="Latest Metrics"
          subtext="Calculate Successes, Failures, and Number of Bills Collected"
          image_src="/img/latest_matrixs.png"
          image_alt="check mark"
          link="/"
        />
        <FPFeatureCard
          title="Error Tracker"
          subtext="Admin track the latest failures and Low scoring confidence scores"
          image_src="/img/latest_errors.png"
          image_alt="check mark"
          link="/"
        />
      </div>

    </div>
    <div className="collector_main_image_bg not_logged_in_color" >
      <div className="collector_main_image_inner" >
        <div className="collector_main_details">
          <h2>
            Is there something else you need? Contact your IT Administrator.
          </h2>
        </div>
      </div>
    </div>

  </div>
);

export default NotLoggedIn;
