import React from 'react';
import logo from '../../img/bpe-LogoColor.svg';
import {
  Navigation
} from '../navigation';
import './style.css';

export const Header = ({
  clientPrincipal
}) => {
  return (
    <header className="header_container">
      <img
        src={logo}
        alt="Best practice energy logo"
      />
      <h1>
        BPE Bill Collector
      </h1>
      {
        clientPrincipal != null &&
        <Navigation/>
      }
      {
        clientPrincipal == null &&
        <div className=""></div>
      }
    </header>
  );
}

export default Header;
