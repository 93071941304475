import React from 'react';
import {
  Link
} from "react-router-dom";
import './style.css';

export const Footer = ({
  clientPrincipal
}) => {
  return (
    <div className="footer_container">
      <div className="footer_text">
        © {new Date().getFullYear()} Best Practice Energy
      </div>
      {
        clientPrincipal != null &&
        <div className="footer_links_container">
          <a href="/logout">
            Logout
          </a>
          <Link to="/plattsdata">
            Platts Data
          </Link>
          <Link to="/">
            Home
          </Link>
        </div>
      }
    </div>
  );
}

export default Footer;
