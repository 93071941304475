import React, { Component } from 'react';
import { Charges } from '../charges';
import { Reviews } from '../reviews';
import './style.css';
import { ButtonClass } from '../../helpers/button_class';

export class BillingStatement extends Component {
  constructor() {
    super();
    this.state = { 
      expanded: false,
      expandedCharges: false,
      expandedReviews: false,
      rescrapeSent: false,
    };
    this.switchExpand = this.switchExpand.bind(this);
    this.expandCharges = this.expandCharges.bind(this);
    this.expandReviews = this.expandReviews.bind(this);
    this.sendRescrapeRequest = this.sendRescrapeRequest.bind(this);
  }

  sendRescrapeRequest() {
    if (!this.state.rescrapeSent) {

      this.setState(state => ({
        rescrapeSent: true
      }));
    }
  }

  switchExpand() {
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
  }

  expandCharges() {
    this.setState(state => ({
      expandedCharges: !this.state.expandedCharges
    }));
  }

  expandReviews() {
    this.setState(state => ({
      expandedReviews: !this.state.expandedReviews
    }));
  }

  render() {
    const {
      data,
      statement,
      charges
    } = this.props;
    
      return (
        <div className="statement_container">

          <div className="statement_row">
            <div className="statement_title_and_open_container">
              <div className="statement_title">
                Billing Statement 
              </div>
              <div onClick={this.switchExpand} className={ButtonClass(this.state.expanded)}>
                {this.state.expanded ? 'CLOSE' : 'OPEN'}
              </div>
            </div>
            { this.state.expanded &&
              <span>
              <tr>
                <td>
                  Id:
                </td> 
                <td>{statement.BillingStatementsId}</td>
              </tr>
              <tr>
                <td>
                  PortalAccountId:
                </td>
                <td>
                  {statement.PortalAccountId}
                </td>
              </tr>
              <tr>
                <td>
                  Salesforce Id: 
                </td>
                <td>
                  {statement.SfStatementId}
                </td>
              </tr>
              <tr>
                <td>
                  CustomerName: 
                </td>
                <td>
                  {statement.CustomerName}
                </td>
              </tr>
              <tr>
                <td>
                  AccountNumber:
                </td> 
                <td>
                  {statement.AccountNumber}
                </td>
              </tr>
              {
                statement.SecondaryAccountNumber &&
                <tr>
                  <td>
                    SecondaryAccountNumber:
                  </td>
                  <td>
                    {statement.SecondaryAccountNumber}
                  </td>
                </tr>
              }
              <tr>
                <td>
                  BalanceForward:
                </td> 
                <td>
                  {statement.BalanceForward}
                </td>
              </tr>
              <tr>
                <td>
                  BillingPeriodStart:
                </td> 
                <td>
                  {statement.BillingPeriodStart}
                </td>
              </tr>
              <tr>
                <td>
                  BillingPeriodEnd: 
                </td>
                <td>
                  {statement.BillingPeriodEnd}
                </td>
              </tr>
              <tr>
                <td>
                  StatementDate:
                </td> 
                <td>
                  {statement.StatementDate}
                </td> 
              </tr>
              <tr>
                <td>
                  CurrentCharges:
                </td> 
                <td>
                  {statement.CurrentCharges}
                </td>
              </tr>
              <tr>
                <td>
                  BalanceForward:
                </td>
                <td>
                  {statement.BalanceForward}
                </td>
              </tr>
              <tr>
                <td>
                  LateFees: 
                </td>
                <td>
                  {statement.LateFees}
                </td>
              </tr>
              <tr>
                <td>
                  TotalDue: 
                </td>
                <td>
                  {statement.TotalDue}
                </td>
              </tr>
              <tr>
                <td>
                  Address1: 
                </td>
                <td>
                  {statement.AccountNumber}
                </td>
              </tr>
              <tr>
                <td>
                  Address2:
                </td>
                <td>
                  {statement.Address2}
                </td>
              </tr>
              <tr>
                <td>
                  City:
                </td>
                <td> 
                  {statement.City}
                </td>
              </tr>
              <tr>
                <td>
                  State:
                </td>
                <td>
                  {statement.State}
                </td>
              </tr>
              <tr>
                <td>
                  Zip:
                </td>
                <td> 
                  {statement.Zip}
                </td>
              </tr>
              <tr>
                <td>
                  RateClass:
                </td>
                <td> 
                  {statement.RateClass}
                </td>
              </tr>
              <tr>
                <td>
                  RateZone:
                </td>
                <td>
                  {statement.RateZone}
                </td>
              </tr>
              <tr>
                <td>
                  Cycle: 
                </td>
                <td>
                  {statement.Cycle}
                </td>
              </tr>
              {
                statement.SummaryDetails &&
                <tr>
                  <td>
                    SummaryDetails: 
                  </td>
                  <td>
                    {statement.SummaryDetails}
                  </td>
                </tr>
              }
              <tr>
                <td>
                  CreatedAt: 
                </td>
                <td>
                  {statement.CreatedAt}
                </td>
              </tr>
              </span>
            }
          </div>
          <div className="statement_row">
            <div className="statement_title_and_open_container">
              <div className="statement_title">
                Charges 
              </div>
              <div onClick={this.expandCharges} className={ButtonClass(this.state.expandedCharges)}>
                {this.state.expandedCharges ? 'CLOSE' : 'OPEN'}
              </div>
            </div>
            {
              this.state.expandedCharges &&
              charges !== null &&
              charges.map(charge => 
                <Charges
                  charge={charge}
                />
              )
            }
          </div>
          {
            data.Status !== "Pass" &&
            <div className="statement_row">
              <div className="statement_title_and_open_container">
                <div className="statement_title">
                  Reviews 
                </div>
                <div onClick={this.expandReviews} className={ButtonClass(this.state.expandedReviews)} >
                  {this.state.expandedReviews ? 'CLOSE' : 'OPEN'}
                </div>
              </div>
                { this.state.expandedReviews &&
                  <Reviews
                    statement={data['StatementReview']}
                    charge={data['ChargesReview']}
                  />
                }
                { this.state.expandedReviews &&
                  <div onClick={this.sendRescrapeRequest} className="submitbutton small_size submitgreen">
                    {this.state.rescrapeSent ? 'Request Sent!' : 'ReScrape Bill'}
                  </div>
                }
            </div>
          }
        </div>
      );
  }  
}

export default BillingStatement;
