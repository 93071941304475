import React, { Component } from 'react';
import './style.css';
import { Loading } from '../../components/loading';
import { doAjax } from '../../helpers/doAjax';
import { Table } from './table';

export class Validations extends Component {
  constructor() {
    super();
    this.state = { 
      validations: [],
      all_offset: 0,
      pass_offset: 0,
      failed_offset: 0,
      data: [],
      pass_data: [],
      failed_data: [],
      selected: 'All'
    };
    this.Select = this.Select.bind(this);
    this.SetOffset = this.SetOffset.bind(this);
    this.Request = this.Request.bind(this);
  }

  async componentDidMount() {
    this.Request();
  }

  SetOffset(selected, new_offset = 0) {
    if (selected === "Failed") {
      this.setState({
        failed_offset: new_offset
      });
    } else if (selected === "Pass") {
      this.setState({
        pass_offset:  new_offset
      });
    } else {
      this.setState({
        all_offset: new_offset
      });
    }
  }
  
  async Request(selected, new_offset = 0) {
    const {
      all_offset,
      pass_offset,
      failed_offset,
      pass_data,
      failed_data,

    } = this.state;

    var offset = 0;
    var count = 0;

    if (selected === "Failed") {
      offset = failed_offset;
      count = failed_data.length;
    } else if (selected === "Pass") {
      offset = pass_offset;
      count = pass_data.length;
    } else {
      offset = all_offset;
      count = this.state.data.length;
    }

    if ((new_offset > offset) || (count === 0 && offset === 0)) {
      this.SetOffset(selected, new_offset + 1);
      var baseurl = process.env.REACT_APP_MIDDLEWARE_API !== undefined ? process.env.REACT_APP_MIDDLEWARE_API : '';
      const url = `${baseurl}api/validations?status=${selected}&offset=${new_offset}`;
      var data = await doAjax(url);

      if (selected === "Failed") {
        this.setState({
          failed_data: [...this.state.failed_data, ...data['message']]
        });
      } else if (selected === "Pass") {
        this.setState({
          pass_data: [...this.state.pass_data, ...data['message']]
        });
      } else {
        this.setState({
          data: [...this.state.data, ...data['message']]
        });
      }
    }


  }

  isSelected(value) {
    var selected = '';
    if (value === this.state.selected) {
      selected = 'options_selected';
    }
    return 'options_title ' + selected;
  }
  
  Select(value) {
    this.setState({
      selected: value
    });
    this.Request(value);
  }

  render() {
    const {
      failed_data,
      pass_data,
      data,
      selected
    } = this.state;
    var table_data = [];
    if (selected === "Failed") {
      table_data = failed_data;
    } else if (selected === "Pass") {
      table_data = pass_data;
    } else {
      table_data = data;
    }

    return (
      <div className="App">
        <div className="page_title">
          <a href="/" className="page_home_link">
            Home &gt;
          </a>
          <div className="page_current_title">
            Validations Page
          </div>
        </div>
        <div className="options_container">
          <div className={this.isSelected("All")} onClick={() => this.Select("All")}>
            All
          </div>
          <div className={this.isSelected("Pass")} onClick={() => this.Select("Pass")}>
            Pass
          </div>
          <div className={this.isSelected("Failed")} onClick={() => this.Select("Failed")}>
            Failed
          </div>
            
        </div>
        {
          table_data.length === 0 && <Loading/>
        } 
        {
          table_data.length > 0 && 
          <Table
            selected={selected}
            table_data={table_data}
            Request={this.Request}
          />
        } 
        
      </div>
    );
  }
}

export default Validations;
