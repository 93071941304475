import React, { Component } from 'react';
import { ButtonClass } from '../../helpers/button_class';

export class ExpandableContent extends Component {
  constructor() {
    super();
    this.state = { 
      expanded: false
    };
    this.switchExpand = this.switchExpand.bind(this);
  }
  switchExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  }  
  
  render() {
    const {
      expanded,
    } = this.state;
    const {
      title
    } = this.props;
    return (
      <div>
        <div className="registrations_title">
          {title}
          <button className={ButtonClass(this.state.expanded)} onClick={this.switchExpand}>
            {this.state.expanded ? 'CLOSE' : 'OPEN'}
          </button>
        </div>
        {
          expanded &&
          this.props.children
        }
      </div>
    );
  }

}