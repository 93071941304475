import React from 'react';
import './style.css';

export const Loading = () => {
  return (
    <div>
      <img 
        src='/img/6.gif'
        alt='...'
      />
    </div>
  );
}

export default Loading;
