import React from 'react';
import './style.css';
import { ErrorMessage } from './errorMessage';

export const Reviews = ({
  statement,
  charge
}) => (
    <div className="review_container">
      {
        statement.length > 0 && 
        statement.map(data =>
          <div>
            <ErrorMessage
              data={data}
            />
          </div> 
        )
      }
      {
        charge.length > 0 && 
        charge.map( (_charge, index) =>
          <div>
            <div>
              Charge: {index}
            </div>
            <div>
              <ErrorMessage
                data={_charge}
              />
            </div>
          </div> 
        )
      }
    </div>
  );

export default Reviews;
