import React from 'react';
import './style.css';
import {
  Link
} from "react-router-dom";

export const FPFeatureCard = ({
  image_src,
  image_alt,
  title,
  subtext,
  link
}) => {
  return (
    <Link className="fpfeature_container" to={link}>
      <div className="feature_container">
        <div  className="feature_item">
          <img 
            src={image_src}
            alt={image_alt}
          />
          </div> 
          <div className="feature_item_content">
            <div className="fpfeature_title">
              {title}
            </div>
            <div className="fpfeature_subtext">
              {subtext}
            </div>
        </div>
      </div>
      
    </Link>
  );
}

export default FPFeatureCard;
