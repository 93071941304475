import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { 
  Header 
} from './components/header';
import { 
  Footer 
} from './components/footer';

import Validations from './page/validations';
import Registrations from './page/registrations';
import NotLoggedIn from './page/notloggedin';
import HomePage from './page/homepage';
import './App.css';

export default class App extends Component {
  constructor() {
    super();
    this.state = { 
      clientPrincipal: null
    }
  }

  async componentDidMount() {
    await this.getUser();
  }

  async getUser() {
    const response = await fetch("/.auth/me");
    const payload = await response.json();
    const { clientPrincipal } = payload;
    this.setState({
      clientPrincipal: clientPrincipal
    })
    return {};
  }
  
  
  render() {
    const {
      clientPrincipal
    } = this.state;

    return (
      <Router>
        <Header
          clientPrincipal={clientPrincipal}
        />
        <div className="body">
          <Switch>
            {
              clientPrincipal != null &&
              <Route path="/validations">
                <Validations />
              </Route>
            }
            {
              clientPrincipal != null &&
              <Route path="/registrations">
                <Registrations />
              </Route>
            }
            {
              clientPrincipal != null &&
              <Route path="/">
                <HomePage />
              </Route>
            }
            <Route path="/">
              <NotLoggedIn />
            </Route>
          </Switch>
        </div>
        <Footer
          clientPrincipal={clientPrincipal}
        />
      </Router>
    );
  }
}