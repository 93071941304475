import React, { Component } from 'react';
import './style.css';
import { ValidationItem } from '../../components/validationItem';

export class Table extends Component {
  paneDidMount = (node) => {    
    if(node) {      
      node.addEventListener("scroll", this.handleScroll.bind(this));      
    }
  }

  handleScroll = (event) => {    
    const {
      selected,
      table_data,
      Request,
    } = this.props;
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom) {
      Request(selected, table_data.length);
    }    
  }

  render() {
    const {
      table_data
    } = this.props;

    return (
      <div className="validation_container">
        <div className="validation_row validation_toprow">
          <div>
            ValidationsId
          </div>
          <div>
            PortalAccountId
          </div>
          <div>
            BillingStatementsId
          </div>
          <div>
            Status
          </div>
          <div>
            &nbsp;
          </div>
        </div>
        <div ref={this.paneDidMount} className="validation_scrollable" id="validation_scrollable">
          {
            table_data.map(validation => 
              <ValidationItem
                key={validation.ValidationsId}
                validation={validation}
              />
            )
          }
        </div>
      </div>
    );
  }
}

export default Table;
