import React from 'react';
const ErrorRows = ({
  message
}) => {
  return Object.entries(message).map( ([key, value]) => (               
    <tr>
      <td>
        {key}
      </td>
      <td>
        {value}
      </td>
    </tr>
  ));
}

export const ErrorMessage = ({
  data
}) => (
  <table className="statement_row">
    {
      data['errors'].map(message =>
        <ErrorRows
          message={message}
        />
      )
    }
  </table>
);
