import React, { Component } from 'react';
import './style.css';
import { RegistrationItem } from '../../components/registrationItem';

// const getStringUpTo = (stringNow, UpTo) => {
//   if (!stringNow.includes(UpTo)) return stringNow;
//   return stringNow.split(UpTo)[0];
// }

// const GetMonthDayYear = (date) => {
//   if (date.includes("T")) {
//     var date = new Date(date);
//     var day = date.getDate();
//     var year = date.getFullYear();
//     var month = date.getMonth()+1;
//     return month+"/"+day+"/"+year;
//   }
//   return date;
// }

export class Table extends Component {
  paneDidMount = (node) => {    
    if(node) {      
      node.addEventListener("scroll", this.handleScroll.bind(this));      
    }
  }

  handleScroll = (event) => {    
    const {
      selected,
      table_data,
      Request,
    } = this.props;
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom && selected !== "Search") {
      Request(selected, table_data.length);
    }    
  }


  render() {
    const {
      table_data
    } = this.props;

    return (
      <div className="registrations_container">
        <div className="registrations_row registrations_toprow">
          <div>
            Customer
          </div>
          <div>
            BillingRegistration
          </div>
          <div>
            Supply/Utility
          </div>
          <div>
            Last Run
          </div>
          <div>
            Last Bill
          </div>
          <div>
            Status
          </div>
          <div>
            &nbsp;
          </div>
        </div>
        <div ref={this.paneDidMount} className="registrations_scrollable" id="registrations_scrollable">
          {
            table_data.map(registration => 
              <RegistrationItem
                key={registration.Id}
                registration={registration}
              />
            )
          }
        </div>
      </div>
    );
  }
}

export default Table;
