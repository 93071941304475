import React from 'react';
import './style.css';

export const Charges = ({
  charge
}) => (
    <div className="charge_container">
      <table className="statement_row">
        <tr>
          <td>
            Id:
          </td> 
          <td>{charge.ChargeId}</td>
        </tr>
        <tr>
          <td>
            Description:
          </td>
          <td>
            {charge.Description}
          </td>
        </tr>
        <tr>
          <td>
            RecordType:
          </td>
          <td>
            {charge.RecordType}
          </td>
        </tr>
        <tr>
          <td>
            Unit: 
          </td>
          <td>
            {charge.Unit}
          </td>
        </tr>
        <tr>
          <td>
            UnitRate:
          </td> 
          <td>
            {charge.UnitRate}
          </td>
        </tr>
        <tr>
          <td>
            Usage:
          </td> 
          <td>
            {charge.Usage}
          </td>
        </tr>
        <tr>
          <td>
            Total:
          </td> 
          <td>
            {charge.Total}
          </td>
        </tr>
        <tr>
          <td>
            Prorate:
          </td> 
          <td>
            {charge.Prorate}
          </td>
        </tr>
        <tr>
          <td>
            BillingPeriodStart:
          </td> 
          <td>
            {charge.BillingPeriodStart}
          </td>
        </tr>
        <tr>
          <td>
            BillingPeriodEnd:
          </td> 
          <td>
            {charge.BillingPeriodEnd}
          </td>
        </tr>
        <tr>
          <td>
            AccountNumber:
          </td> 
          <td>
            {charge.AccountNumber}
          </td>
        </tr>
      </table>
    </div>
  );

export default Charges;
