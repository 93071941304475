import React, { Component } from 'react';
import './style.css';
import { Loading } from '../../components/loading';
import { doAjax } from '../../helpers/doAjax';
import { ExpandableContent } from '../expandableContent';
import { DateGraph } from '../dategraph';
import { ButtonClass } from '../../helpers/button_class';

const GetMonthDayYear = (date) => {
  if (date === null) return '';
  if (date.includes("T")) {
    date = new Date(date);
    var day = date.getDate();
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    return `${month}/${day}/${year}` ;
  }
  return date;
}

const GetFullTimeLine = (date) => {
  if (date == null) return '';
  if (date.includes("T")) {
    date = new Date(date);
    var day = date.getDate();
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var hours = date.getHours();
    var ampm = "AM";
    if (hours > 11) ampm = "PM";
    if (hours > 12) {
      hours -= 12;
    }
    return `${month}/${day}/${year} ${hours}:${date.getMinutes()}:${date.getSeconds()} ${ampm}` ;
  }
  return date;
}

const GroupDatesPerAccountNumber = (data) => {
  var grouped = {};
  for (var i = 0; i < data.length; i++) {
    var group = data[i];
    if (!(group.AccountNumber in grouped)) {
      grouped[group.AccountNumber] = [group];
      // grouped[group.AccountNumber] = [GetMonthDayYear(group.BillingPeriodEnd)];
    } else {
      grouped[group.AccountNumber].push(group);
    }
  }
  return grouped;
  // return grouped_and_sorted;
}


export class RegistrationItem extends Component {
  constructor() {
    super();
    this.state = { 
      expanded: false,
      data: null,
      dateGraph: null
    };
    this.switchExpand = this.switchExpand.bind(this);
    this.clicked = this.clicked.bind(this);
    this.setDataSet = this.setDataSet.bind(this);
  }

  async switchExpand() {
    const {
      registration
    } = this.props;
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
    // watermarks, latestlogdb, billingstatements to get: Count of Account Number, All Billingstatements accountnumber and dates

    var baseurl = process.env.REACT_APP_MIDDLEWARE_API !== undefined ? process.env.REACT_APP_MIDDLEWARE_API : '';
    const url = `${baseurl}api/statement_logs?id=${registration['PortalRegistrations'][0]['Id']}`;
    var data = await doAjax(url);
    this.setState({
      data: data
    });
  }

  setDataSet = (dateSet) => {
    this.setState({
      dateGraph: dateSet
    });
    return dateSet;
  }

  clicked() {

  }

  render() {
    const {
      expanded,
      data
    } = this.state;
    const {
      registration
    } = this.props;
    
    // if (data != null) {
    //   data['Watermarks'] = [
    //     { PortalAccountId: "31327", AccountLabel: "G32 Account", LatestDateLabel: "03/11/2021", UpdatedOn: "2021-03-16T00:00:00", WatermarkId: 158, CreatedAt: null, LatestBillingPeriodEndDate: '2/11/2021', AccountNumber: '9834267' },
    //     { PortalAccountId: "31327", AccountLabel: "G02 Account", LatestDateLabel: "03/09/2021", UpdatedOn: "2021-03-16T00:00:00", WatermarkId: 159, CreatedAt: null, LatestBillingPeriodEndDate: '2/07/2021', AccountNumber: '09981231' },
    //     { PortalAccountId: "31327", AccountLabel: "Large Gas", LatestDateLabel: "03/11/2021", UpdatedOn: "2021-03-16T00:00:00", WatermarkId: 160, CreatedAt: null, LatestBillingPeriodEndDate: '2/11/2021', AccountNumber: 'C09123123' },
    //     { PortalAccountId: "31327", AccountLabel: "Small Gas", LatestDateLabel: "03/09/2021", UpdatedOn: "2021-03-16T00:00:00", WatermarkId: 161, CreatedAt: null, LatestBillingPeriodEndDate: '1/17/2021', AccountNumber: '1238890' }
    //   ]
    //   // console.log(data['Watermarks'][0]);
    //   // console.log(data['Watermarks'][1]);
    //   // console.log(data['Watermarks'][2]);
    //   // console.log(data['Watermarks'][3]);
    //   // console.log(data['Watermarks'][4]);
    // //   data['Watermarks'] = 
    // }
    return (
      <div className="registrations_row_container">
        { 
        registration['portalAccountView'][0] &&
          <div className="registrations_row">
            <div>
              {registration['portalAccountView'][0].CustomerName}
            </div>
            <a className="column_max_100" href={`https://bestpracticeenergy.my.salesforce.com/${registration.BillingRegistrationsId}`} target="_blank" rel = "noopener noreferrer">
              {registration.BillingRegistrationsId}
            </a>
            <div>
              {registration['portalAccountView'][0].HostName}
            </div>
            <div>
              {GetFullTimeLine(registration.CrawlerStartedAt)}
            </div>

            {
              registration['billingStatement'].length > 0 &&
              <div>
                {GetMonthDayYear(registration['billingStatement'][0]['BillingPeriodEnd'])}
              </div>
            }
            {
              registration['billingStatement'].length === 0 &&
              <div>
                NONE
              </div>
            }
            { 
              registration.Status === "Success" &&
              <div className="registrations_pass">
                {registration.Status}
                <br/>
                {registration.StatusDetail}
              </div>
            }
            { 
              registration.Status !== "Success" &&
              <div className="registrations_fail">
                {registration.Status}
                <br/>
                {registration.StatusDetail}
              </div>
            }
            <div>
              <button className={ButtonClass(this.state.expanded)} onClick={this.switchExpand}>
                {this.state.expanded ? 'CLOSE' : 'OPEN'}
              </button>
            </div>
          </div>
        }
        {
          expanded &&
          data === null &&
          <Loading/>
        }
        {
          expanded &&
          data !== null &&
          <div className="registrations_data_opened">
            <ExpandableContent
              title="Latest Log Message"
            >
              <div>
                <div className="registrations_watermark_container registrations_watermark_row extend_padding_down" >
                  <div>
                    Started At
                  </div>
                  {
                    registration.CompletedAt != null &&        
                    <div>
                      Completed At
                    </div>
                  }
                  {
                    registration.BillCount != null &&
                    <div>
                      Bill Count
                    </div>
                  } 
                </div>
                <div className="registrations_watermark_container registrations_watermark_row" >
                  <div className="reg_text">
                    {GetFullTimeLine(registration.CrawlerStartedAt)}
                  </div>
                  <div className="reg_text">
                    {GetFullTimeLine(registration.CompletedAt)}
                  </div>
                  <div className="reg_text">
                    {registration.BillCount}
                  </div>
                </div>
                <div className="registrations_watermark_container registrations_watermark_row" >
                  {
                    registration.LastCrawlerMessage != null  &&
                    <div>
                      Message: <span className="reg_text">{registration.LastCrawlerMessage}</span>
                    </div>
                  }
                  {
                    registration.LastCrawlerMessage == null  &&
                    <div>
                      Crawler is still running, more information will appear here when the 
                      crawl is complete
                    </div>
                  }
                </div>
                <div className="registrations_watermark_container registrations_watermark_row" >
                  {
                    registration.link !== "" &&
                    <div>
                      ScreenShot: <span className="reg_text"><a href={registration.link} target="_blank" rel = "noopener noreferrer">Open</a></span>
                    </div>
                  }

                </div>
              </div>
            </ExpandableContent>
            <ExpandableContent
              title="Watermarks"
            >
              <div>
                <div className="registrations_watermark_container registrations_watermark_row watermark_title flex_to_content">
                  <div className="watermark_column">
                    AccountLabel
                  </div>
                  <div className="watermark_column">
                    Date Label
                  </div>
                  <div className="watermark_column">
                    Account Number
                  </div>
                  <div className="watermark_column">
                    Billing End Date
                  </div>
                  <div className="watermark_column">
                    Salesforce Id
                  </div>
                  <div className="watermark_column">
                    Updated On
                  </div>
                </div>
                <div className="registrations_breakline"></div>
                {data['Watermarks'].map(data => 
                  <div>
                    {
                      data.AccountLabel !== "All" &&
                      <div  key={data.WatermarkId} className="registrations_watermark_container registrations_watermark_row flex_to_content" >
                        <div className="accounts_latest_date">
                          {data.AccountLabel}
                        </div>
                        <div className="accounts_latest_date">
                          {data.LatestDateLabel}
                        </div>
                        <div className="accounts_latest_date">
                          {data.AccountNumber}
                        </div>
                        {
                          data.SfStatementId !== '' &&
                          <div className="accounts_latest_date">
                            {GetMonthDayYear(data.LatestBillingPeriodEndDateInSalesforce)}
                          </div>
                        }
                        {
                          data.SfStatementId !== '' &&
                          <div className="accounts_latest_date sf_statement_id">
                            {data.SfStatementId}
                          </div>
                        }
                        {data.SfStatementId === '' && <div className="accounts_latest_date sf_statement_id"/> }
                        {data.SfStatementId === '' && <div className="accounts_latest_date sf_statement_id"/> }
  
                        <div className="accounts_latest_date">
                          {GetMonthDayYear(data.UpdatedOn)}
                        </div>
                      </div>
                    }
                  </div>
                )}
              </div>
            </ExpandableContent>
            <ExpandableContent
              title="Bills Per Account"
            >
              <div>
              {
                data['AccountCounts'] !== null &&
                data['AccountCounts'] !== [] &&
                <div className="registrations_count registrations_watermark_row watermark_title account_number_container">
                  <div>
                    Account Number
                  </div>
                  <div>
                    Bill Count
                  </div>
                </div>
              }
              {data['AccountCounts'].map(count_info => (

                count_info["AccountNumber"] &&
                <div className="registrations_count registrations_watermark_row watermark_title account_number_container">
                  <div className="accounts_latest_date">
                    {count_info["AccountNumber"].toString()}
                  </div>

                  <div className="accounts_latest_date">
                    {count_info[""].toString()}
                  </div>
                </div>
              ))}
              </div>
            </ExpandableContent>
            <ExpandableContent
              title="Grouped Bill Dates"
            >
              <div>
              {Object.entries(GroupDatesPerAccountNumber(data['AccountAndDates'])).map (([key, value]) =>
                key !== "null" &&
                <div className="registrations_watermark_row watermark_title" key={value['AccountNumber']}>

                  <div>
                    {key}
                  </div>
                  <DateGraph
                    key={value['AccountNumber']}
                    dates={value}
                    setDataSet={this.setDataSet}
                  />
                </div>
              )}
              </div>
            </ExpandableContent>
          </div>
        }

      </div>
    );
  }
}

export default RegistrationItem;
