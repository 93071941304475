export async function doAjax(url) {
  const results = await fetch(url, {mode: 'cors'})
    .then(function(response) {
      return response.json();
    })
    .then(function(results) {
      return results;
    })
    .catch(function(error) {
      return {'message': '', 'status':'failed'};
    });
  return results;
}

export default doAjax;
